@import "src/theme/colors.scss";

.container {
  align-items: center;
  display: flex;
}

.link {
  font-size: 12px !important;
  padding: 9px 18px;
  white-space: nowrap;
}

.selected {
  color: $accent !important;
}
