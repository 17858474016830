@import "src/theme/colors.scss";

.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 25px;
}

.logo {
  cursor: pointer;
  width: 50px;
}

.sidePanel {
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 25px;
}

.openNavigation {
  font-size: 30px;
  color: $primary-variant-1 !important;
}

.secondary {
  background-color: $primary-variant-2;
}
