@import "src/theme/colors.scss";

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}

.rating {
  background-color: $accent;
  top: 0;
  left: 0;
  height: 100%;
  position: absolute;
}

.wrapper {
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.background {
  position: absolute !important;
  align-items: center;
  display: flex;
  position: relative;
}

.star {
  fill: $accent;
}

.backgroundStar {
  fill: $white;
}
