@import "src/theme/media.scss";

.container {
  padding: 0 50px 90px;

  @media #{$responsive} {
    padding: 0 0 25px;
  }
}

.stepTitle {
  font-size: 32px;

  @media #{$mobile} {
    font-size: 24px;
  }
}

.stepContent {
  font-size: 24px;

  @media #{$mobile} {
    font-size: 18px;
  }
}

.step {
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 353px;
  padding: 0 15px;
  height: 800px;

  @media #{$mobile} {
    height: fit-content;
    flex-direction: row;
    padding: 10px;
    width: 100%;
  }
}

.steps {
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
  width: 100%;

  @media #{$tablet} {
    justify-content: center;
  }
}

.title {
  font-size: 48px;

  @media #{$tablet} {
    width: 500px !important;
  }

  @media #{$mobile} {
    font-size: 32px;
  }
}

.subtitle {
  font-size: 32px;
  margin: 10px auto 95px !important;
  width: 880px !important;

  @media #{$tablet} {
    margin: 25px 0 45px !important;
    width: 100% !important;
  }

  @media #{$mobile} {
    font-size: 20px;
    width: 100% !important;
    margin: 25px auto 45px !important;
  }
}

.contentWrapper {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}
