@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.wrapper {
  position: relative;
  aspect-ratio: 1440 / 739;

  @media #{$mobile} {
    background-color: $primary-variant-2;
    aspect-ratio: 375 / 719;
  }
}

.mobileFill {
  position: absolute;
  width: 100%;
  height: 300px;
  z-index: 0;
  background-color: $white;
  top: 0;
  left: 0;

  @media #{$tablet} {
    height: 300px !important;
  }
}

.background {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: 1;
}

.primaryFill {
  background-color: $primary-variant-1;
}

.whiteFill {
  background-color: $white;
}

.primary2Fill {
  background-color: $primary-variant-2;
}

.subtitle {
  @media #{$responsive} {
    width: 80% !important;
  }
}

.content {
  z-index: 2;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 45px;
  height: 100%;

  @media #{$responsive} {
    padding: 50px 0 0;
    gap: 15px;
  }
}

.container {
  margin-top: 50px;
  height: 100%;

  @media #{$responsive} {
    padding: 25px;
  }
}

.formWrapper {
  @media #{$tablet} {
    width: 80%;
  }

  @media #{$mobile} {
    width: 100%;
  }
}

.form {
  width: 700px;
  margin-bottom: 45px;

  @media #{$tablet} {
    margin-bottom: 0;
    width: 100%;
  }

  @media #{$mobile} {
    width: 100%;
  }
}

.submit {
  margin: 45px auto 0;

  @media #{$responsive} {
    width: 100%;
  }
}

.shape1 {
  position: absolute;
  width: 600px;
  height: 600px;
  fill: $accent !important;
  left: 0;
  bottom: 0;
  transform: translate(-35%) rotate(10deg);
}
