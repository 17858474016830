@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.wrapper {
  background-color: $accent;
}

.container {
  justify-content: center;
  align-items: center;
  gap: 45px;
  flex-direction: row;
  padding: 25px;

  @media #{$responsive} {
    flex-direction: column;
    gap: 15px;
  }
}
