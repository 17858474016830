@import "src/theme/colors.scss";
@import "src/theme/media.scss";

%container {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: transparent;
  width: 100%;
  padding: 20px;

  @media #{$responsive} {
    background-color: $secondary-variant-1;
  }
}

.prompt {
  text-align: center !important;
}

.container {
  @extend %container;

  & > * {
    & + * {
      margin-left: 45px;
    }
  }
}

.responsiveContainer {
  @extend %container;

  gap: 7.5px;
}

.link {
  font-size: 15px !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  color: $primary-variant-2 !important;
  text-decoration: underline;

  @media #{$mobile} {
    text-align: center;
  }
}
