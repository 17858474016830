@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.container {
  background-color: $background-variant-1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 70px 100px;

  @media #{$responsive} {
    padding: 25px;
  }
}

.top {
  display: flex;
  justify-content: space-between;
}

.bottom {
  border-top: 2px solid $supplemental-variant-1;
  padding-top: 25px;
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}

.logo {
  width: 190px;
  padding-bottom: 35px;
  height: fit-content;
}

.topActionsWrapper {
  gap: 45px;
  align-items: flex-start;
  display: flex;
}

.topActions {
  display: flex;
  flex-direction: column;
}

.bottomActions {
  align-items: center;
  display: flex;
  gap: 45px;
}

.topLink {
  padding: 10px 30px;
}

.signIn {
  & * {
    font-size: 12px !important;
  }
}
