@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.link {
  font-size: 16px !important;
  margin: 25px 0 55px !important;
  border-bottom: 2px solid $accent;
  color: $accent;
  width: fit-content;

  & > * {
    text-transform: unset !important;
    letter-spacing: unset !important;
    font-size: 20px !important;
  }
}

.wrapper {
  background-color: $light-gray;
}

.container {
  align-items: center;
  padding: 55px;

  @media #{$responsive} {
    padding: 50px 25px;
  }
}

.content {
  align-items: center;
  display: flex;

  @media #{$responsive} {
    gap: 50px;
    flex-direction: column-reverse;
  }
}

.preTitle {
  font-size: 32px;

  @media #{$responsive} {
    font-size: 24px;
  }
}

.title {
  font-size: 64px;
  margin: 10px 0 !important;

  @media #{$tablet} {
    font-size: 40px;
    text-align: center !important;
  }

  @media #{$mobile} {
    font-size: 32px;
    text-align: center !important;
  }
}

.signUp {
  width: 400px;

  @media #{$tablet} {
    margin: 0 auto;
  }

  @media #{$mobile} {
    margin: 50px auto 0;
    width: 250px;
  }
}

.text {
  font-size: 20px;
  margin: 15px 0 30px !important;

  @media #{$mobile} {
    width: 100% !important;
    text-align: center !important;
  }

  @media #{$tablet} {
    text-align: center !important;
    margin: 15px auto 30px !important;
    width: 550px !important;
  }
}

.panelTitle {
  @media #{$tablet} {
    width: 100% !important;
    text-align: center !important;
  }

  @media #{$mobile} {
    margin: 0 auto !important;
    width: 300px !important;
    text-align: center !important;
  }
}

%panel {
  padding: 25px;
  flex: 1;

  @media #{$responsive} {
    padding: 0;
  }
}

.leftPanel {
  @extend %panel;

  @media #{$mobile} {
    justify-content: center;
  }
}

.rightPanel {
  @extend %panel;

  align-items: center;
  display: flex;
  justify-content: center;
}

.items {
  gap: 35px;
  display: flex;
  flex-direction: column;

  @media #{$responsive} {
    display: none;
  }
}

.item {
  gap: 15px;
  align-items: center;
  display: flex;
}

.icon {
  height: 40px;
  width: 40px;
}

.bullet {
  @media #{$mobile} {
    margin: 0 auto !important;
    width: 250px !important;
    text-align: left !important;
  }

  @media #{$tablet} {
    margin: 0 auto !important;
    width: 400px !important;
    text-align: left !important;
  }
}
