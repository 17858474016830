@import "src/theme/media.scss";
@import "src/theme/colors.scss";

.container {
  display: flex;

  @media #{$responsive} {
    width: 100%;
    overflow: auto;
    scroll-snap-type: x mandatory;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.child {
  scroll-snap-align: start;

  @media #{$responsive} {
    min-width: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

.indicators {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.indicator {
  transition: all 0.5s ease-in-out;
  height: 3px;
  width: 32px;
  background-color: $primary-variant-2;
  margin: 0 5px;
  border-radius: 5px;
}

.selectedIndicator {
  background-color: $accent !important;
}
