@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.carousel {
  background-color: $white;
  width: 100vw !important;
}

.container {
  @media #{$responsive} {
    padding: 25px 0;
    width: 100%;
  }
}
