.container {
  flex-shrink: 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
