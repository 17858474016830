@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.container {
  overflow: visible;
  padding: 65px 25px 65px;

  @media #{$mobile} {
    padding: 80px 25px 50px;
  }

  @media #{$tablet} {
    padding: 65px 25px 65px;
  }
}

.title {
  font-size: 64px;

  @media #{$tablet} {
    font-size: 48px;
  }

  @media #{$mobile} {
    font-size: 24px;
  }
}

.subtitle {
  font-size: 28px;
  margin: 25px 0 !important;

  @media #{$tablet} {
    font-size: 24px;
  }

  @media #{$mobile} {
    font-size: 20px;
  }
}

.steps {
  gap: 25px;
  display: flex;
  flex-direction: column;
}

.imageWrapper {
  height: 250px;

  @media #{$mobile} {
    position: relative;
    width: 100%;
    max-height: 200px;
  }
}

.step {
  background-color: $background-variant-1;
  overflow: hidden;
  border-radius: 5px;
  background-color: $light-gray;
  align-items: center;
  display: flex;
  height: 250px;

  @media #{$tablet} {
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  @media #{$mobile} {
    min-height: 500px;
    height: fit-content;
    max-height: 600px;
    flex-direction: column;
  }
}

.stepContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 25px;
  width: calc(100% - 250px);

  @media #{$mobile} {
    padding: 25px;
    width: 100%;
  }
}

.stepTitle {
  font-size: 32px;

  @media #{$tablet} {
    font-size: 24px;
  }

  @media #{$mobile} {
    font-size: 24px;
  }
}

.stepInformation {
  font-size: 20px;

  @media #{$tablet} {
    font-size: 18px;
  }

  @media #{$mobile} {
    font-size: 18px;
  }
}

.mobileImage {
  object-fit: cover;
  width: 100%;

  @media #{$tablet} {
    display: none;
  }

  @media #{$desktop} {
    display: none;
  }
}

.iconButton {
  margin: 0 auto 100px;

  @media #{$mobile} {
    margin-bottom: 25px;
  }
}

.icon {
  width: 200px;

  @media #{$mobile} {
    width: 150px;
  }
}

.learnMore {
  margin: 50px auto 0;
  width: 460px;

  @media #{$mobile} {
    width: 260px;
  }
}
