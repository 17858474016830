@import "src/theme/media.scss";
@import "src/theme/colors.scss";

.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  overflow: visible;
  padding: 0 75px;
  height: 860px;
  position: relative;

  @media #{$desktop} {
    padding-top: 200px;
  }

  @media #{$tablet} {
    height: 550px;
    padding: 0 25px;
  }

  @media #{$mobile} {
    flex-direction: column;
    padding: 0 25px;
    height: fit-content;
  }
}

.heading {
  display: flex;
  flex-direction: column;

  width: 620px;

  @media #{$tablet} {
    width: 50%;
  }

  @media #{$mobile} {
    width: 100%;
  }
}

.hidden {
  display: none;
}

.getEstimate {
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 45px;

  @media #{$desktop} {
    position: relative;
  }

  @media #{$tablet} {
    position: relative;
  }

  @media #{$mobile} {
    margin: 0;
    min-height: 80vw;
    width: 100%;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media #{$tablet} {
    gap: 15px;
  }
}

.cta {
  margin-top: 35px !important;
  width: 400px !important;

  @media #{$mobile} {
    width: 100% !important;
  }
}

.paragraph {
  width: 650px !important;
}

.estimateBackground {
  left: 0;
  position: absolute;
  top: 0;

  @media #{$tablet} {
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  @media #{$mobile} {
    width: fit-content;
    bottom: -75vw;
    top: unset;
    left: 50%;
    width: 150vw;
    height: 150vw;
    transform: translate(-50%, 0);
  }
}

.title {
  font-size: 64px !important;

  @media #{$tablet} {
    font-size: 48px !important;
  }

  @media #{$mobile} {
    width: 100%;
    font-size: 48px !important;
  }
}

.subtitle {
  font-size: 32px !important;

  @media #{$tablet} {
    font-size: 24px !important;
  }

  @media #{$mobile} {
    width: 100%;
    font-size: 24px !important;
  }
}

.info {
  font-size: 24px !important;

  @media #{$tablet} {
    font-size: 20px !important;
  }

  @media #{$mobile} {
    width: 100%;
    font-size: 20px !important;
  }
}
