@import "src/theme/colors.scss";

.container {
  background-color: $primary-variant-2;
  padding: 25px;
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  flex-direction: column;
  padding: 25px 0;
  gap: 15px;
}

.topActions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.bottom {
  border-top: 1px solid $primary-variant-1;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.logo {
  width: min(250px, 100%);
  margin: 40px 0 50px;
}

.signIn {
  max-width: min(315px, 100%);
  margin-bottom: 10px;
}
