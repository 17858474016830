@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.wrapper {
  background-color: $primary-variant-2;
}

.container {
  flex-direction: row;
  overflow: visible;
  height: 800px;

  @media #{$tablet} {
    height: fit-content;
    padding: 0;
  }

  @media #{$mobile} {
    flex-direction: column;
    height: fit-content;
  }
}

.leftPanel {
  width: 30%;
  position: relative;

  @media #{$tablet} {
    width: 40%;
    width: fit-content;
  }

  @media #{$mobile} {
    width: 100%;
  }
}

.imageWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-70%, -50%);

  @media #{$tablet} {
    top: unset;
    left: unset;
    transform: unset;
    position: relative;
  }

  @media #{$mobile} {
    top: unset;
    left: unset;
    transform: unset;
    position: relative;
    margin: auto;
    width: fit-content;
  }
}

.cta {
  margin: 0 auto;
  width: fit-content;

  @media #{$responsive} {
    margin-top: 25px !important;
  }
}

.rightPanel {
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 35px;
  padding: 140px 100px;
  width: 70%;

  @media #{$tablet} {
    gap: 15px;
    padding: 40px 100px 40px 20px;
    //width: 100%;
  }

  @media #{$mobile} {
    gap: 15px;
    padding: 40px 20px;
    width: 100%;
  }
}

.values {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.value {
  gap: 25px;
  align-items: center;
  display: flex;
}

.check {
  width: 30px;
  height: 30px;
  fill: $secondary-variant-1;
}
