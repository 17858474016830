@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 40px;
  width: 280px;
  height: 100%;
  padding: 50px 0;

  @media #{$mobile} {
    margin: 0;
    padding: 50px 40px;
    width: 100vw;
  }
}

.logo {
  height: 40px;
  margin-bottom: 15px;
}
