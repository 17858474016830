@import "src/theme/media.scss";

.img {
  & > * {
    width: 100% !important;
  }
}

.subtitle {
  @media #{$responsive} {
    width: 90% !important;
  }
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 100px 50px 100px;
  gap: 40px;

  @media #{$responsive} {
    padding: 40px 0;
    gap: 15px;
  }
}

.locations {
  width: 100%;
}

.location {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100%;
}

.reviewContent {
  font-size: 14px !important;
  margin: 0 auto;
  width: 100% !important;
  margin-top: 25px;
  margin-bottom: 20px;
}

.review {
  gap: 15px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.reviewer,
.role {
  font-size: 14px !important;
  letter-spacing: 1px !important;
}

.rating {
  margin-top: 52px;
}

.locName {
  margin-top: 15px;
}
