@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.wrapper {
  background-color: $primary-variant-1;
}

.container {
  padding: 25px;

  @media #{$responsive} {
    padding: 55px 25px;
  }
}

.title {
  @media #{$mobile} {
    width: 300px !important;
  }
}

.metrics {
  margin-top: 30px;
  align-items: center;
  display: flex;

  @media #{$mobile} {
    flex-direction: column;
  }
}
