$primary-variant-1: #0d291c;
$primary-variant-2: #e1dfda;

$secondary-variant-1: #2b5f54;
$secondary-variant-2: #296379;

$supplemental-variant-1: #647f7f;
$supplemental-variant-2: #ffffff;

$accent: #b06148;

$background-variant-1: $primary-variant-2;

$black: #484848;

$gray: #e1dfda;
$gray75: #7a7a7a;
$gray50: #dbdbdb;
$gray25: #f0f0f0;

$light-gray: #f2f1ec;

$white: #fff;

$card-shadow: 0px 0px 15px rgba($black, 0.25);

$tilt-shadow: drop-shadow(0px 5px 7px rgb(0 0 0 / 0.05));

:export {
  primaryColor: $primary-variant-1;
  white: $white;
}
